import lko from "./imgs/life-events/lko.jpg";
import iitk from "./imgs/life-events/iit-k.jpg";
import iitr from "./imgs/life-events/iit-r.jpg";
import lkolpc from "./imgs/life-events/lko-ggi.jpg";
import ramaG from "./imgs/life-events/rama-g.jpg";
import touchtalent from "./imgs/life-events/touchtalent.jpg";
import mishtag from "./imgs/life-events/mishtag.png";

export const dataPhotos = [
  {
    src: iitr,
    title:
      "We secured the 3rd Prize in the Task-Oriented Automation Robotics Event hosted during Robosapience, the tech fest organized by IIT Roorkee.",
    width: 3,
    height: 4,
    type: "full",
  },
  {
    src: lko,
    title:
      "Presented seminar on Ethical Hacking Security and Awareness at Lucknow Public College.",
    width: 4,
    height: 3,
    type: "medium",
  },
  {
    src: iitk,
    title:
      "We made it to the Super 8 at the Robotics Competition in IIT Kanpur in 2011, and our team name was even mentioned in the newspapers.",
    width: 1,
    height: 1,
    type: "large",
  },
  {
    src: lkolpc,
    title:
      "Gave a seminar on ethical hacking security and awareness at a government girl's engineering college.",
    width: 3,
    height: 4,
    type: "medium",
  },
  {
    src: ramaG,
    title:
      "I won the first prize in a web page design competition at “Manthan 13,” an annual cultural tech fest hosted by Rama Group of Institutions.",
    width: 3,
    height: 4,
    type: "large",
  },
  {
    src: mishtag,
    title:
      "The mishTag team had a party to celebrate their success after being featured and securing funding.",
    width: 4,
    height: 3,
    type: "medium",
  },
  {
    src: touchtalent,
    title:
      "Recognized as part of the top 1% in the worldwide community of creative individuals, I was honored with the Best of 2013 award on TouchTalent.com.",
    width: 3,
    height: 4,
    type: "full",
  },
];
