import React, { useEffect, useRef } from "react";
import { useSpring } from "react-spring";
import BackButton from "../BackButton/BackButton";
import "./LifeEvents.css";

import { configMain } from "../../App";

const LifeEvents = ({ photos, handleClose }) => {
  const springRef = useRef();
  const { opacity } = useSpring({
    ref: springRef,
    opacity: 1,
    config: configMain,
  });
  useEffect(() => {
    document.getElementById("gallery")?.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <BackButton
        onClick={handleClose}
        style={{
          opacity,
        }}
      />
      <section id="gallery" className="gallery">
        <div className="sub-heading-wrapper">
          <h2 className="sub-heading">Life Events</h2>
        </div>
        <div className="grid">
          {photos.map((el, i) => {
            return (
              <div
                key={`gl_${i}`}
                className={`item reel item--${el.type}`}
                style={{
                  background: "url(" + el.src + ")",
                  backgroundSize: "cover",
                }}
              >
                <div className="item__details">{el.title}</div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
export default LifeEvents;
