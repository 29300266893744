// import { ReactComponent as CarIcon } from "./icons/experiments-car.svg";
// import { ReactComponent as BikeIcon } from "./icons/experiments-bike.svg";
// import { ReactComponent as StairsIcon } from "./icons/experiments-stairs.svg";

import { PiFileHtmlFill } from "react-icons/pi";
import { SiOpenai, SiDigitalocean } from "react-icons/si";

const dataExperiments = [
  {
    href: "https://careernewton.com/",
    title: "OpenAI Free Resume Builder",
    desc: "Solely built as a user-friendly resume builder that utilizes OpenAI's models to assist in creating a CV or resume that satisfies recruiter standards for content and layout, based on market leaders.",
    Icon: SiOpenai,
  },
  {
    href: "https://cyberin.in/",
    title: "Cyberin, Simplified Hosting",
    desc: "During my college days, I embarked on a fun project that leveraged DigitalOcean’s cloud computing and cPanel. The aim was to provide users with a hassle-free way to obtain a small hosting droplet for deploying their websites.",
    Icon: SiDigitalocean,
  },
  {
    href: "https://gopdf.pro/",
    title: "GoPdf, HtmlToPDF API",
    desc: "GoPDF is a solo project designed to streamline the conversion of HTML to PDF via an API. The project is currently under development and is expected to go live in a few months.",
    Icon: PiFileHtmlFill,
  },
];

export default dataExperiments;
