import { RiNpmjsLine, RiGithubLine } from "react-icons/ri";

const dataOpenSource = [
  {
    href: "https://www.npmjs.com/package/htmltopdf-weasyprint",
    title: "htmlToPDF WeasyPrint",
    desc: " lightweight Node.js wrapper for the weasyprint command-line tool. It converts HTML documents to PDFs asynchronously using WebKit.",
    Icon: RiNpmjsLine,
  },
  {
    href: "https://github.com/himstar/Advanced-Image-Mapping-Tool",
    title: "Image mapping tool",
    desc: "Image Mapping Tool for Web Developers: This tool helps web developers create image maps with coordinates and generate custom CSS easily.",
    Icon: RiGithubLine,
  },
];

export default dataOpenSource;
