// import { ReactComponent as MediumIcon } from "./icons/medium.svg";
import { SiWritedotas } from "react-icons/si";

const dataOpenSource = [
  {
    href: "https://tricksway.com/the-most-popular-javascript-design-patterns-in-one-chapter/",
    title: "The most popular JavaScript design patterns..",
    desc: "Apr 4, 2018",
    Icon: SiWritedotas,
  },
  {
    href: "https://tricksway.com/how-to-fix-high-cpu-usages-in-google-chrome/",
    title: "How to fix high CPU usages in Google Chrome...",
    desc: "Nov 11, 2016",
    Icon: SiWritedotas,
  },
  {
    href: "https://tricksway.com/why-you-should-say-no-to-facebook-free-basic-internet-offer/",
    title: "Why you should say 'No' to facebook free basic internet...",
    desc: "Feb 8, 2016",
    Icon: SiWritedotas,
  },
];

export default dataOpenSource;
