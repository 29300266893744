

import vsSm from "./imgs/vs-banner.png";
import vsLg from "./imgs/vs-banner.png";
import vsLogo from "./imgs/vs-logo.png";

import skidosSm from "./imgs/skidos-aps.png";
import skidosLg from "./imgs/skidos-aps.png";
import skidosLogo from "./imgs/skidos-logo.png";

import hscSm from "./imgs/hsc-banner.png";
import hscLg from "./imgs/hsc-banner.png";
import hscLogo from "./imgs/hughes-systique-logo.png";

import ctsSm from "./imgs/cts-banner.png";
import ctsLg from "./imgs/cts-banner.png";
import ctsLogo from "./imgs/cts-logo.png";

import boldSm from "./imgs/bold-banner.png";
import boldLg from "./imgs/bold-banner.png";
import boldLogo from "./imgs/bold-logo.png";

import {
  FaJs,
  FaReact,
  FaAws,
  FaHtml5,
  FaMap,
  FaNode,
  FaCss3,
} from "react-icons/fa";
import { RiOpenaiFill } from "react-icons/ri";
import { SiMongodb, SiExpress, SiJest } from "react-icons/si";
import { TbBrandCypress } from "react-icons/tb";

const data = [
  {
    title: "Victoria's Secret",
    path: "/vs&co",
    offsetY: 0,
    logo: vsLogo,
    icons: [
      {
        Icon: FaHtml5,
        title: "HTML5",
      },
      {
        Icon: RiOpenaiFill,
        title: "OpenAI",
      },
      {
        Icon: FaReact,
        title: "React",
      },
      {
        Icon: FaJs,
        title: "JavaScript",
      },
    ],
    url: "https://www.victoriassecret.com/",
    imageLg: vsLg,
    imageSm: vsSm,
    subtitle: "Victoria's Secret",
    description: ` `,
    theme: "#000",
    textColor: "#fff",
  },
  {
    title: "Skidos",
    path: "/skidos",
    offsetY: 90,
    logo: skidosLogo,
    icons: [
      {
        Icon: FaHtml5,
        title: "HTML5",
      },
      {
        Icon: RiOpenaiFill,
        title: "OpenAI",
      },
      {
        Icon: FaReact,
        title: "React",
      },
      {
        Icon: FaJs,
        title: "JavaScript",
      },
      {
        Icon: FaAws,
        title: "AWS",
      },
      {
        Icon: TbBrandCypress,
        title: "CyPress",
      },
    ],
    url: "https://skidos.com/",
    imageLg: skidosLg,
    imageSm: skidosSm,
    subtitle: "Skidos Labs ApS",
    description: `<ul>
    <li>Migrated front-end applications from <b>AWS EC2 to AWS S3 serverless</b>, improving architecture, <b>saving 30% on costs</b>, enhancing scalability, and bolstering availability by 25%.</li>
    <li>Extensively worked on deploying new architectural applications and managing multiple environments <b>using AWS CloudFront, LightSail, and Route53.</b></li>
    <li>Strategic implementation resulted in a <b>40% reduction in ticket response</b> time and increase in customer retention, fostering enhanced satisfaction and unwavering loyalty.</li>
    <li>Led technical analysis, UX, and API design discussions with cross-functional teams, resulting in secure and performative solutions that decreased and performed better user experience by reducing errors by 30%.</li>
    <li>Streamlined development processes through Agile programming, encompassing sprint planning, task distribution, and code review; reduced time-to-market by 30% and enhanced code quality.</li>
    </ul>`,
    theme: "#C1F1F9",
    textColor: "#333333",
  },
  {
    title: "Hughes Systique",
    path: "/hsc",
    offsetY: 55,
    logo: hscLogo,
    icons: [
      {
        Icon: FaJs,
        title: "JavaScript",
      },
      {
        Icon: FaReact,
        title: "ReactJS",
      },
      {
        Icon: FaNode,
        title: "NodeJS",
      },
      {
        Icon: FaAws,
        title: "AWS",
      },
      {
        Icon: SiJest,
        title: "Jest",
      },
      {
        Icon: FaMap,
        title: "MapBox",
      },
    ],
    url: "https://hsc.com/",
    imageLg: hscLg,
    imageSm: hscSm,
    subtitle: "Hughes Systique Corporation",
    description: `
      <ul>
        <li><b><i>Client:</i> Comtech (911):</b></li>
        <li>Spearheaded an architectural transformation in the 911 app by transitioning from Google Maps to Mapbox’s APIs, resulting in upgraded mapping accuracy and better user experience.</li>
        <li>Developed a Kibana plugin using React and TypeScript, which directly connects to the Parse API data, leveraging Elasticsearch’s binary to <b>boost search performance by an impressive 50%</b>.</li>
        <li>Created dashboards and visualizations with Amazon QuickSight and D3.js, resulting in a 30% improvement in data interpretation and decision-making for stakeholders.</li>
      </ul>
      `,
    theme: "#7ccaa0",
    textColor: "#fff",
  },
  {
    title: "Cognizant",
    path: "/cts",
    offsetY: 50,
    logo: ctsLogo,
    icons: [
      {
        Icon: FaHtml5,
        title: "HTML / CSS",
      },
      {
        Icon: FaJs,
        title: "JavaScript",
      },
      {
        Icon: FaReact,
        title: "ReactJS",
      },
      {
        Icon: SiExpress,
        title: "Express JS",
      },
      {
        Icon: SiMongodb,
        title: "MongoDB",
      },
      {
        Icon: SiJest,
        title: "Jest",
      },
    ],
    url: "https://cognizant.com/",
    imageLg: ctsLg,
    imageSm: ctsSm,
    subtitle: "Cognizant Technology Solutions",
    description: `
      <ul>
        <li><b><i>Clients:</i> E&Y, Standard Chartered:</b></li>
        <li>Delivered result performance by 30% by implementing security patterns like <b>JWT and performance patterns like Lazy-loading and Pre-Loading.</b></li>
        <li>Over a period of six months, migrated more than 3 Vue.js applications to React.js. This transition not only improved efficiency, but also involved the development of new features.</li>
        <li>Exceeded expectations for <b>optimizing the existing security of 1000+ lines of code</b>, mitigating potential risks and enhancing system security.</li>
        <li>Followed Test-Driven Development Practices, writing unit tests using Jest and Jasmine. This approach helped define the overall testing strategy to <b>achieve 100% code coverage.</b></li>
        <li>Achieved full code coverage using web debugging tools like Postman, Chrome Dev Tools, and IE Debugger, resulting in a 50% decrease in unresolved bugs.</li>
      </ul>    
    `,
    theme: "#f4f592",
  },
  {
    title: "Bold Technologies",
    path: "/bold",
    offsetY: 40,
    logo: boldLogo,
    icons: [
      {
        Icon: FaHtml5,
        title: "HTML",
      },
      {
        Icon: FaCss3,
        title: "CSS",
      },
      {
        Icon: FaJs,
        title: "JavaScript",
      },
      {
        Icon: FaReact,
        title: "ReactJS",
      },
    ],
    url: "https://bold.com",
    imageLg: boldLg,
    imageSm: boldSm,
    subtitle: "Bold Technologies",
    description: `
    <ul>
      <li>Developed key features for a career portal, leading to a <b>20% increase in user engagement</b>, features included advanced search, personalized job recommendations, and a streamlined application process.</li>
      <li>Enhanced the codebase and libraries, resulting in improvement in project efficiency and effectiveness.</li>
      <li>Upgraded application with React JS, resolving cross-browser compatibility issues in popular browsers and <b>increased cross-browser performance by 15%.</b></li>
      <li>Leveraged Optimizely to conduct A/B experiments, multivariate testing, and multi-page funnel testing, resulting in a 25% increase in conversion rates.</li>
    </ul>`,
    theme: "#dce9f8",
  },
];

export default data;
