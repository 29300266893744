import React, { useRef } from "react";
import { useTrail, useChain } from "react-spring";

import SocialLink from "./SocialLink";
import { configBouncey } from "../../App";

import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { PiCodesandboxLogo, PiCodepenLogoFill } from "react-icons/pi";

const links = [
  {
    href: "mailto:himstar@himstar.info?subject=Let's Connect&body=Hey, ",
    screenReaderText: "E-mail",
    svg: <FaEnvelope />,
  },
  {
    href: "https://codesandbox.io/u/himstar",
    screenReaderText: "CodeSandbox",
    svg: <PiCodesandboxLogo />,
  },
  {
    href: "https://github.com/himstar/",
    screenReaderText: "Github",
    svg: <FaGithub />,
  },
  {
    href: "https://codepen.io/himstar/",
    screenReaderText: "CodePen",
    svg: <PiCodepenLogoFill />,
  },
  {
    href: "https://www.linkedin.com/in/himstar/",
    screenReaderText: "LinkedIn",
    svg: <FaLinkedin />,
  },
];

const SocialLinks = ({ inert }) => {
  const springRef = useRef();

  const trail = useTrail(links.length, {
    ref: springRef,
    config: configBouncey,
    y: 0,
    opacity: 1,
    // delay: 200,
    from: { y: 100, opacity: 0 },
  });

  useChain([springRef], [0.5]);

  return (
    <ul className="social-links" inert={inert ? "" : undefined}>
      {trail.map((style, index) => {
        const item = links[index];
        return (
          <SocialLink
            style={style}
            key={item.href}
            href={item.href}
            screenReaderText={item.screenReaderText}
            svg={item.svg}
          />
        );
      })}
    </ul>
  );
};

export default SocialLinks;
