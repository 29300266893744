import React, { useState, useEffect } from "react";
import useLocation from "wouter/use-location";
import { Helmet } from "react-helmet";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

import Dragger from "./Components/Dragger/Dragger";
import Title from "./Components/Title/Title";
import SocialLinks from "./Components/SocialLinks/SocialLinks";
import Detail from "./Components/Detail/Detail";
import List from "./Components/List/List";

import cardData from "./data.js";
import dataOpenSource from "./dataOpenSource.js";
import dataVolunteer from "./dataVolunteer.js";
import dataWords from "./dataWords.js";
import dataExperiments from "./dataExperiments";
import LifeEvents from "./Components/LifeEvents/LifeEvents";
import { dataPhotos } from "./dataLiveEvents";
import { Route } from "wouter";
import { FaExternalLinkAlt as OpenIcon } from "react-icons/fa";

import "./App.css";

export const breakpoint = 768;
export const configMain = { tension: 500, friction: 80, mass: 3 };
export const configBouncey = { mass: 5, tension: 1600, friction: 100 };
export const prefersDarkMode = matchMedia(
  "(prefers-color-scheme: dark)"
).matches;
export const colors = {
  white: "#fff",
  black: "#333",
};

const App = () => {
  const [isExpanded, setIsExpanded] = useState(null);
  const [defaultPage, setDefaultPage] = useState(true);
  // Routing
  const [location, setLocation] = useLocation();
  const matchedLocationFromData = cardData.find(
    (item) => item.path === location
  );
  useEffect(() => {
    if (matchedLocationFromData) {
      setIsExpanded(matchedLocationFromData.title);
    } else {
      setIsExpanded(null);
    }
  }, [matchedLocationFromData]);

  return (
    <main className="container">
      <Route path="/:name">
        {(params) => {
          if (params.name === "life-events") {
            setDefaultPage(false);
            return (
              <>
                <Helmet>
                  <meta
                    content={prefersDarkMode ? colors.black : colors.white}
                    name="theme-color"
                  />
                </Helmet>
                <LifeEvents
                  photos={dataPhotos}
                  handleClose={() => {
                    setDefaultPage(true);
                    setLocation("/");
                  }}
                />
              </>
            );
          } else {
            setDefaultPage(true);
          }
        }}
      </Route>
      {defaultPage && (
        <>
          <Helmet>
            <meta
              content={prefersDarkMode ? colors.black : colors.white}
              name="theme-color"
            />
          </Helmet>
          <Title />
          <SocialLinks inert={!!isExpanded} />
          <Dragger
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            setLocation={setLocation}
            inert={isExpanded}
          />
          <Detail
            shouldDisableBodyScroll={matchedLocationFromData}
            active={cardData.find((x) => x.title === isExpanded)}
            handleClose={() => {
              setIsExpanded(null);
              clearAllBodyScrollLocks();
              window.history.back(); // otherwise back button adds to the history stack
            }}
          />

          <List title="Experiments" data={dataExperiments} inert={isExpanded} />
          <List title="Open Source" data={dataOpenSource} inert={isExpanded} />
          <List title="Writing" data={dataWords} inert={isExpanded} />
          <List
            title="Certifications"
            data={dataVolunteer}
            inert={isExpanded}
          />
          <div className="sub-heading-wrapper">
            <h2
              className="sub-heading cursor-pointer"
              onClick={() => setLocation("/life-events")}
            >
              <a className="Detail-anchor" rel="noopener noreferrer">
                Life Events
                <OpenIcon className="Icon-open" />
              </a>
            </h2>
          </div>
        </>
      )}
    </main>
  );
};

export default App;
