// import { ReactComponent as HyfIcon } from "./icons/hyf.svg";
import { FaHackerrank, FaAws } from "react-icons/fa";

const dataVolunteer = [
  {
    href: "https://www.hackerrank.com/certificates/c3885199d683",
    title: "JavaScript (Intermediate) Hackathon Certificate",
    desc: "Won JavaScript Skill Hackathon hosted by HackerRank.",
    Icon: FaHackerrank,
  },
  {
    title: "AWS Innovate - AI/ML Edition",
    desc: "Issued by Amazon in February 2022, Certificate of Attendance for AWS Innovate - AI/ML Edition",
    Icon: FaAws,
  },
  {
    href: "https://www.hackerrank.com/certificates/8161398b9690",
    title: "Problem Solving (Intermediate) Hackathon Certificate",
    desc: "Won Problem Solving Hackathon hosted by HackerRank. ",
    Icon: FaHackerrank,
  },
];

export default dataVolunteer;
